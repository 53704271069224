<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <div style="display: flex">
            <div>
              <div class="search_center">
                合同名称：
                <el-autocomplete
                  class="ipt_width project_width"
                  v-model="parameter.agreementName"
                  :fetch-suggestions="agreementQuerySearch"
                  placeholder="请输入或选择合同名称"
                  @select="onProkectNameSelect"
                  :popper-append-to-body="false"
                >
                </el-autocomplete>
              </div>
              <div class="search_center">
                合同编号：<el-input class="ipt_width" v-model="parameter.agreementNum"></el-input>
              </div>
              <div class="search_center">
                内容摘要：
                <el-input class="ipt_width" v-model="parameter.contentAbstract"></el-input>
              </div>
              <div class="search_center">
                合同状态：
                <el-select
                  v-model="parameter.status"
                  placeholder="请选择合同状态"
                  clearable
                  class="ipt_width"
                >
                  <el-option
                    v-for="item in StautsType"
                    :label="item.dictName"
                    :value="item.dictVal"
                    :key="item.dictVal"
                  ></el-option>
                </el-select>
              </div>
              <div class="search_center">
                业主单位：
                <el-select
                  class="ipt_width"
                  v-model="parameter.customersUnit"
                  placeholder="请选择业主单位"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in customersUnit"
                    :label="item"
                    :value="item"
                    :key="item"
                  ></el-option>
                </el-select>
              </div>
              <div class="search_center">
                业务种类：
                <el-select
                  class="ipt_width"
                  v-model="parameter.businessLines"
                  placeholder="请选择业务种类"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in dictData.businessLines"
                    :label="item.dictName"
                    :value="item.dictVal"
                    :key="item.dictVal"
                  ></el-option>
                </el-select>
              </div>
              <div class="search_center">
                项目承接公司：
                <el-select
                  class="ipt_width"
                  v-model="parameter.companyType"
                  placeholder="请选择项目承接公司"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="item in dictData.companyType"
                    :label="item.dictName"
                    :value="item.dictVal"
                    :key="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="search_center">
                合同属性：
                <Dictionary
                  v-model="parameter.agreementAttribute"
                  code="CONTRACT_ATTRIBUTE_TYPE"
                  placeholder="请选择合同属性"
                />
              </div>
              <div class="search_center">
                合同金额收齐状态：
                <Dictionary
                  v-model="parameter.proceedsStatus"
                  code="AGREEMENT_MONEY_STATUS"
                  placeholder="请选择合同金额收齐状态"
                />
              </div>
              <div class="search_center">
                签订时间：
                <el-date-picker
                  class="date_width"
                  v-model="createdDate"
                  :pickerOptions="pickerOptions"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="签订时间（始）"
                  end-placeholder="签订时间（末）"
                >
                </el-date-picker>
              </div>
              <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
                >搜索</el-button
              >
              <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
              <el-button
                type="primary"
                icon="el-icon-printer"
                v-if="permission(['AGREEMENT_EXPORT'])"
                @click="exporting()"
                >导出</el-button
              >
            </div>
            <div>
              <el-radio-group v-model="ProjectNature">
                <el-radio border size="medium" style="width: 100%; margin-right: 0px" label="ALL"
                  >全部</el-radio
                >
                <div style="display: flex">
                  <span
                    style="border: 1px solid #dcdfe6; padding: 8px 0px 0px 5px"
                    v-if="dictAgreementNameList[0] && dictAgreementNameList[0][0]"
                  >
                    <el-radio size="medium" :label="dictAgreementNameList[0][0].produceVal">{{
                      dictAgreementNameList[0][0].produceName
                    }}</el-radio>
                    <div style="padding-left: 10px">
                      <el-checkbox-group v-model="produceList">
                        <template v-for="item in dictAgreementNameList[0]">
                          <div :key="item.id">
                            <el-checkbox :label="item.dictVal">{{ item.dictName }}</el-checkbox>
                          </div>
                        </template>
                      </el-checkbox-group>
                    </div>
                  </span>
                  <span
                    style="border: 1px solid #dcdfe6; padding: 8px 0px 0px 5px"
                    v-if="dictAgreementNameList[1] && dictAgreementNameList[1][0]"
                  >
                    <el-radio size="medium" :label="dictAgreementNameList[1][0].cooperateVal">{{
                      dictAgreementNameList[1][0].cooperateName
                    }}</el-radio>
                    <div style="padding-left: 10px">
                      <el-checkbox-group v-model="cooperateList">
                        <template v-for="item in dictAgreementNameList[1]">
                          <div :key="item.id">
                            <el-checkbox :label="item.dictVal">{{ item.dictName }}</el-checkbox>
                          </div>
                        </template>
                      </el-checkbox-group>
                    </div>
                  </span>
                </div>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-descriptions class="margin-top" :column="2" border>
            <el-descriptions-item
              label-class-name="labelClassName"
              content-class-name="contentClassName"
            >
              <template slot="label">
                <span style="color: #000; font-size: 16px">合同金额统计</span>
              </template>
              <span style="color: red">{{
                agreementAmount.actualContractAmount | applyAmount
              }}</span>
            </el-descriptions-item>
            <el-descriptions-item
              label-class-name="labelClassName"
              content-class-name="contentClassName"
            >
              <template slot="label">
                <span style="color: #000; font-size: 16px">实际金额统计</span>
              </template>
              <span style="color: red">{{ agreementAmount.actualAmount | applyAmount }}</span>
            </el-descriptions-item>
          </el-descriptions>
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            @sort-change="sortChange"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="320"
              fixed="left"
              prop="agreementName"
              label="合同名称"
            ></el-table-column>
            <el-table-column
              align="center"
              width="150"
              prop="agreementNum"
              label="合同编号"
            ></el-table-column>
            <el-table-column prop="" width="120" align="center" label="项目性质">
              <template slot-scope="scope">
                {{ scope.row.projectSource | dict(dictData.projectNature) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="projectSource"
              sortable
              width="120"
              align="center"
              label="项目来源"
            >
              <template slot-scope="scope">
                {{ scope.row.projectSource | dict(dictData.projectSource) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="businessLines"
              sortable
              width="120"
              align="center"
              label="业务种类"
            >
              <template slot-scope="scope">
                {{ scope.row.businessLines | dict(dictData.businessLines) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              width="130"
              prop="companyType"
              label="项目承接公司"
            >
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(dictData.companyType) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              sortable
              width="180"
              :show-overflow-tooltip="false"
              prop="agreementAttribute"
              label="合同属性"
            >
              <template slot-scope="scope">
                {{ scope.row.agreementAttribute | dict(dictData.agreementAttribute) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              min-width="220"
              prop="party"
              label="业主单位"
            ></el-table-column>
            <el-table-column
              align="center"
              width="130"
              sortable
              prop="signedDate"
              label="签订开始时间"
            >
              <template slot-scope="scope">
                {{ scope.row.signedDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="160"
              sortable
              :show-overflow-tooltip="false"
              prop="actualContractAmount"
              label="合同金额(元)"
            >
              <template slot-scope="scope">
                {{ scope.row.actualContractAmount | applyAmount }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="160"
              sortable
              :show-overflow-tooltip="false"
              prop="actualAmount"
              label="实际金额(元)"
            >
              <template slot-scope="scope">
                {{ scope.row.actualAmount | applyAmount }}
              </template>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="projectName"
              label="相关项目"
            ></el-table-column> -->
            <el-table-column
              align="center"
              width="175"
              sortable
              prop="proceedsStatus"
              label="合同金额收齐状态"
            >
              <template slot-scope="scope">
                {{ scope.row.proceedsStatus | dict(dictData.proceedsStatus) }}
              </template>
            </el-table-column>
            <el-table-column align="center" width="140" prop="status" label="合同状态">
              <template slot-scope="scope">
                {{ scope.row.status | dict(dictData.agreementStatus) }}
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" width="120">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="text_Details_Bgc"
                  size="small"
                  @click.stop="edit(scope.row)"
                  >详情</el-button
                >
                <el-popover placement="bottom" width="140" trigger="click">
                  <el-button
                    v-if="
                      scope.row.operatingButton &&
                      scope.row.operatingButton.some(r => {
                        return r == 'DELETE'
                      })
                    "
                    class="text_Remove_Bgc"
                    type="text"
                    size="small"
                    @click="del(scope)"
                    >删除</el-button
                  >
                  <el-button
                    class="text_Grant_Bgc"
                    type="text"
                    v-if="scope.row.status != 30 && scope.row.status == 20"
                    size="small"
                    @click="executeFinshFn(scope.row)"
                    >执行完毕</el-button
                  >
                  <span slot="reference" class="more">更多</span>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            :total="parameter.total"
            layout="total,prev,pager,next,slot, jumper"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" v-if="showEditDialog" :options="options"></Edit>
    <!-- 执行完毕 -->
    <ExecuteDinshDialog
      v-if="executeFinshShow"
      :agreementShow.sync="executeFinshShow"
      @submitFinshFn="submitFinshFn"
    />
  </div>
</template>
<script>
import * as agreement from '@/api/agreement'
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'

export default {
  components: {
    Edit: () => import('../Edit.vue'),
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    ExecuteDinshDialog: () => import('../execute-finsh-dialog.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        agreementNum: null,
        contentAbstract: null,
        status: null,
        agreementName: null,
        businessLines: null,
        companyType: null,
        customersUnit: null,
        agreementAttribute: null,
        proceedsStatus: null,
        projectSourceList: [],
      },
      createdDate: [],
      tableData: [],
      pageSize: 0,
      dictData: {
        projectSource: [],
        agreementStatus: [],
        proceedsStatus: [],
        businessLines: [],
        projectNature: [],
        companyType: [],
        agreementAttribute: [],
      },
      projectNatureInfo: [],
      StautsType: [],
      loading: false,
      options: {},
      showEditDialog: false,
      agreementList: [],
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      agreementAmount: {
        //合同金额统计
        actualContractAmount: 0, //合同金额
        actualAmount: 0, //实际金额
      },
      produceList: [],
      cooperateList: [],
      customersUnit: [],
      ProjectNature: 'ALL',
      agreementLoading: false, //合同按钮
      executeFinshShow: false, //执行完毕弹窗
      executeFinshAgreementId: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    dictAgreementNameList() {
      let arr = [[], []]
      this.projectNatureInfo.forEach(v => {
        this.dictData.projectSource.forEach(item => {
          if (v.dictVal.indexOf(item.dictVal) != -1 && v.dictName == '生产项目') {
            arr[0].push({
              id: item.id,
              produceName: v.dictName,
              produceVal: v.dictVal,
              dictVal: item.dictVal,
              dictName: item.dictName,
            })
          } else if (v.dictVal.indexOf(item.dictVal) != -1 && v.dictName == '合作项目') {
            arr[1].push({
              id: item.id,
              cooperateName: v.dictName,
              cooperateVal: v.dictVal,
              dictVal: item.dictVal,
              dictName: item.dictName,
            })
          }
        })
      })
      return arr
    },
  },
  watch: {
    tableData: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
    $route: {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val.query && val.query.data) {
          const item = JSON.parse(val.query.data)
          if (item) {
            this.edit(item)
            // 获取当前页面的 URL
            let currentUrl = window.location.href
            // 清除查询参数部分
            let cleanUrl = currentUrl.split('?')[0]
            // 修改浏览器的历史记录，但不会触发页面刷新
            window.history.replaceState({}, document.title, cleanUrl)
          }
        }
      },
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getType()
    this.getData()
    this.getAgreement()
  },
  provide() {
    return {
      refresh: this.refresh,
    }
  },
  filters: {
    applyAmount(n) {
      if (!n) {
        return 0
      } else {
        return Number(n).toLocaleString()
      }
    },
  },
  methods: {
    async submitFinshFn(info) {
      const obj = {
        ...info,
        id: this.executeFinshAgreementId,
      }
      const res = await this.$api.agreement.executionCompleted(obj)
      if (res.code === 200) {
        this.$message.success('操作成功')
      }
      this.executeFinshShow = false
    },
    //执行完毕
    executeFinshFn(row) {
      this.executeFinshAgreementId = null
      this.executeFinshAgreementId = row.id
      this.executeFinshShow = true
    },
    // 合同导出功能
    exporting() {
      this.agreementLoading = true
      this.$api.agreement
        .agreementExport(this.parameter)
        .then(res => {
          const fileName = '合同数据明细.xlsx'
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }

          this.$message({
            message: '导出成功!',
            type: 'success',
          })
          this.agreementLoading = false
        })
        .catch(e => {
          this.agreementLoading = false
        })
    },
    agreementQuerySearch(queryString, cb) {
      let agreementList = this.agreementList.map(item => {
        return { value: item.agreementName }
      })
      let results = queryString ? this.projectCreateFilter(queryString) : agreementList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    projectCreateFilter(queryString) {
      let results = this.agreementList.map(item => {
        return { value: item.agreementName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.agreementName = e.value
      }
      this.$forceUpdate()
    },
    sortChange(column) {
      this.loading = true
      this.tableData = []
      if (column) {
        const orderMapping = {
          signedDate: 'signed_date',
          actualContractAmount: 'actual_contract_amount',
          actualAmount: 'actual_amount',
          projectSource: 'project_source',
          businessLines: 'business_lines',
          companyType: 'company_type',
          proceedsStatus: 'proceeds_status',
        }
        const orderRuleMapping = {
          ascending: 'ASC',
          descending: 'DESC',
        }
        if (orderMapping[column.prop] && orderRuleMapping[column.order]) {
          this.parameter.order = orderMapping[column.prop]
          this.parameter.orderRule = orderRuleMapping[column.order]

          if (this.ProjectNature != 'ALL') {
            let arr = [...this.produceList, ...this.cooperateList]
            this.ProjectNature.forEach(v => {
              if (arr.indexOf(v) == -1) {
                arr.push(v)
              }
            })
            this.parameter.projectSourceList = [...arr]
          } else {
            this.parameter.projectSourceList = [...this.produceList, ...this.cooperateList]
          }
        } else if (column.order == null) {
          this.parameter.order = null
          this.parameter.orderRule = null
        }
        this.$api.agreement
          .list(this.parameter)
          .then(res => {
            this.tableData = res.data.records
            this.parameter.total = res.data.total
            this.loading = false
          })
          .catch(err => {
            this.loading = false
          })
      }
    },
    handleReset() {
      this.parameter.status = null
      this.parameter.contentAbstract = null
      this.parameter.proceedsStatus = null
      this.parameter.agreementNum = null
      this.parameter.agreementName = null
      this.parameter.businessLines = null
      this.parameter.companyType = null
      this.parameter.customersUnit = null
      this.parameter.agreementAttribute = null
      this.parameter.signedStartDate = null
      this.parameter.signedEndDate = null
      this.parameter.pageNow = 1
      this.createdDate = []
      this.parameter.projectSourceList = []
      this.ProjectNature = 'ALL'
      this.cooperateList = []
      this.produceList = []
      this.getData()
    },
    getAgreement() {
      this.$api.agreement
        .list({ pageSize: 10000 })
        .then(res => {
          this.agreementList = res.data.records
          this.agreementList.forEach(v => {
            if (this.customersUnit.indexOf(v.party) == -1) {
              this.customersUnit.push(v.party)
            }
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    refresh() {
      this.showEditDialog = false
      this.pageChangeHandler(1)
    },
    // add() {
    //   this.options = {};
    //   this.showEditDialog = true;
    // },
    edit(row) {
      this.options = row.deepClone()
      this.options.Root = 1
      this.showEditDialog = true
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    async getData() {
      this.loading = true

      if (this.createdDate && this.createdDate != []) {
        this.parameter.signedStartDate = new Date(this.createdDate[0]).getTime()
        this.parameter.signedEndDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == [] || this.createdDate == null) {
        this.parameter.signedStartDate = null
        this.parameter.signedEndDate = null
      }
      if (this.ProjectNature != 'ALL') {
        let arr = [...this.produceList, ...this.cooperateList]
        this.ProjectNature.forEach(v => {
          if (arr.indexOf(v) == -1) {
            arr.push(v)
          }
        })
        this.parameter.projectSourceList = [...arr]
      } else {
        this.parameter.projectSourceList = [...this.produceList, ...this.cooperateList]
      }
      this.$api.agreement
        .list(this.parameter)
        .then(res => {
          this.tableData = res.data.records
          this.parameter.total = res.data.total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })

      const RES = await this.$api.agreement.listAgreementSumAmount(this.parameter)
      this.agreementAmount = RES.data
        ? RES.data
        : {
            actualContractAmount: 0, //合同金额
            actualAmount: 0, //实际金额
          }
    },
    del(scope) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.agreement
            .del(scope.row.id)
            .then(res => {
              this.$message.success('删除成功！')

              var hasLastPage =
                this.parameter.pageSize * this.parameter.pageNow >= this.parameter.total
              var lastData = this.tableData.length < 2
              if (lastData && hasLastPage && this.parameter.pageNow > 1) {
                this.parameter.pageNow--
              }
              this.getData()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          //用户取消
        })
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    getType() {
      this.$api.dict
        .listSysDictData('AGREEMENT_STATUS')
        .then(res => {
          this.StautsType = initTreeData(res.data)
          this.dictData.agreementStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_NATURE', true)
        .then(res => {
          const arr = []
          res.data.forEach((v, i) => {
            if (v.dictVal) {
              let str = v.dictVal.split(',')
              arr.push({
                dictName: v.dictName,
                dictVal: str,
              })
              this.projectNatureInfo.push({
                id: i,
                dictName: v.dictName,
                dictVal: str,
              })
            }
          })

          arr.forEach((item, i) => {
            item.dictVal.forEach((value, index) => {
              this.dictData.projectNature.push({
                dictName: item.dictName,
                dictVal: value,
                id: Date.now() + ++index + ++i,
              })
            })
          })
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('BUSINESS_LINES', true)
        .then(res => {
          this.dictData.businessLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('PROJECT_SOURCE', true)
        .then(res => {
          this.dictData.projectSource = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('CONTRACT_ATTRIBUTE_TYPE', true)
        .then(res => {
          this.dictData.agreementAttribute = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('AGREEMENT_MONEY_STATUS', true)
        .then(res => {
          this.dictData.proceedsStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
/deep/.labelClassName {
  width: 150px;
}
/deep/.contentClassName {
  width: 40%;
}
/deep/.el-checkbox {
  width: 102px;
  // border: 1px solid #dcdfe6;
  padding: 10px 20px 0 10px;
  border-radius: 4px;
  height: 36px;
  margin-right: 10px;
}
/deep/ .el-autocomplete-suggestion {
  width: auto !important;
}
/deep/ .mainList_content > .mainList_content_sub > .el-table {
  height: calc(100% - 122px);
}
/deep/ .more {
  background-color: #aacdee;
  color: #fff;
  padding: 5px 5px;
  margin-left: 8px;
}
.text_Remove_Bgc {
  background-color: #f78888;
  color: #fff;
  padding: 5px 5px;
}
.text_Grant_Bgc {
  //发放
  background-color: #85ce61c2;
  color: #fff;
  padding: 5px 5px;
}
</style>
